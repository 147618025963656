import { Katilas } from "./Katilas";

export const MOCK_KATILAI_EE = [
    new Katilas({
        id: 1,
        name: "Pellet Uni",
        type: "Graanul",
        imageUrl: "assets/pellet_uni.png",
        pjuvis: "assets/pellet_uni_pjuvis.png",
        feature: "Kõrgeim klass",
        price: 3400,
        pricing: [
            [12, 3400, 0, [0, 30, 80, 110]],
            [16, 3530, 0, [0, 30, 80, 110]],
            [20, 3650, 120, [0, 30, 80, 110]],
            [26, 4070, 160, [0, 30, 80, 110]],
            [36, 4330, 160, [0, 30, 80, 110]],
        ],
        minPower: 12,
        maxPower: 36,
        power: [12, 16, 20, 26, 36],
        minArea: 120,
        maxArea: 360,
        efficiencyClass: "A++",
        fuelTypes: ["Graanulid", "Küttepuud", "Brikett", "Kivisüsi"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 120, 160, 200, 260, 360],
            ["Põlemiskambri sügavus", "mm", 455, 455, 455, 505, 505],
            ["Kütuse laadimine", "l/dm\u00b3", 55, 55, 65, 84, 93],
            ["Kütuse maht", "l/dm\u00b3", 230, 230, 250, 270, 270],
            ["Pöörlev pelletipõleti", "kW", "4-16", "4-16", "5-20", "6-26", "8-36"],
            ["Soojusvaheti pindala", "m\u00b2", 2.2, 2.6, 2.9, 3.4, 3.7],
            ["Kütuse laadimisava suurus", "cm", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Horisontaalsete soojusvahetite kogus", "tk", 3, 4, 4, 4, 4],
            ["Vee kogus boileris", "l", 59, 63, 68, 80, 85],
            ["Mass", "kg", 260, 280, 310, 360, 380],
            ["Nõutav tõmme korstnas", "Pa", 12, 13, 14, 15, 15],
        ],
        additionalData: [
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "90%"],
            ["Korstna läbimõõt (sisemus)", "150 mm"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u2074 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
        ],
        selections: [
            ["Kuro talpa", "230 l", "260 l", "320 l", "350 l"],
            [
                ["Degiklis"],
                ["12", "4-16 kW"],
                ["16", "4-16 kW"],
                ["20", "4-16 kW", "5-20 kW"],
                ["26", "5-20 kW", "6-26 kW"],
                ["36", "6-26 kW", "8-36 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1150, 1260, 1260, 1260, 1260],
            ["B", "mm", 440, 440, 490, 540, 590],
            ["C", "mm", 640, 640, 640, 690, 690],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Võimsus", "10-36 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pu1ee", "pu2ee", "pu3ee", "pu4ee", "pu5ee", "pu6ee", "pu7ee"],
        iconLocations: [[245, 150], [208, 350], [320, 380], [245, 150], [242, 330], [315, 70], [355, 170]],
        description: "Kõrgeima kategooria ja tehnilise tasemega graanulkütuse katel neljaosalise soojusvaheti ja keraamilise deflektoriga. Malmrest ja keraamika deflektor soodustavad ja tagavad sujuva põlemise. See lubab saavutada katla kasuteguri üle 90%. Täisautomaatne pöördpõleti on ette nähtud madalama kvaliteedi ja suurema tuhasisaldusega graanulite põletamiseks. Pöörlev põlemiskamber aitab kütust täielikult põletada ja takistab räbu teket. Kontroller juhib kõiki katlaruumi puhastamise ja katla põlemise protsesse.",
        burnerDescription: "Täisautomaatne graanulite põleti, hea kuni keskmise kvaliteediga graanulite põletamiseks. Põletil on automaatne puhastusmehhanism - pöörlev põlemis- ja õhuvarustuskamber, mille abil põleti puhastab end tuhast ja räbust.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/pelletUniDimensions.svg",
        certificatePdf: "../assets/Bandymu sertifikatas Pellet Uni.pdf",
        instructionsPdf: "../assets/pellet_uni_instruction_ee.pdf",
        techSpecPdf: "../assets/pellet_uni_ee.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/pelletUniPajungimasEE.pdf",
    }),
    new Katilas({
        id: 2,
        name: "Bio Kompakt",
        type: "Graanul",
        imageUrl: "assets/bio_kompakt.png",
        pjuvis: "assets/bio_kompakt_pjuvis.png",
        feature: "Uus",
        price: 3400,
        pricing:[
            [10, 3400, 0, [0]],
            [15, 3550, 0, [0]],
            [20, 3700, 0, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 15, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A++",
        fuelTypes: ["Graanulid"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 100, 150, 200],
            ["Kütuse maht", "l/dm\u00b3", 120, 160, 190],
            ["Pöörlev pelletipõleti", "kW", "3-12", "4-15", "6-20"],
            ["Soojusvaheti pindala", "m\u00b2", 1.7, 2.0 , 2.4],
            ["Horisontaalsete soojusvahetite kogus", "tk", 2, 2, 2],
            ["Kütmise efektiivsus", "%", "89", "90", "91"],
            ["Vee kogus boileris", "l", 45, 53, 61],
            ["Mass", "kg", 185, 215, 240],
        ],
        additionalData: [
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Korstna läbimõõt (sisemus)", "130 mm"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u2074 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", "12 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 470, 520, 570],
            ["B", "mm", 645, 695, 754],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Võimsus", "10-20 kW"],
        ],
        icons: ["bk1ee", "bk2ee", "bk3ee", "bk4ee", "bk5ee", "bk6ee", "bk7ee"],
        iconLocations: [[290, 386], [230, 360], [375, 380], [298, 325], [298, 325], [0, 0], [414, 345]],
        description: "Kompaktklassi graanulitel töötav katel, keraamilise põlemiskambri, keraamilise deflektori ja suure soojusvahetiga. Kauakestev kõrgtemperatuuriline keraamika toetab põlemist ja tagab sujuva põlemisprotsessi. See lubab saavutada katla kasuteguri üle 90%. Täisautomaatne pöördpõleti on ette nähtud madalama kvaliteedi ja suurema tuhasisaldusega graanulite põletamiseks. Pöörlev põlemiskamber aitab kütust täielikult põletada ja takistab räbu teket. Kontroller juhib kõiki katlaruumi puhastamise ja katla põlemise protsesse.",
        burnerDescription: "Täisautomaatne graanulite põleti, hea kuni keskmise kvaliteediga graanulite põletamiseks. Põletil on automaatne puhastusmehhanism - pöörlev põlemis- ja õhuvarustuskamber, mille abil põleti puhastab end tuhast ja räbust.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/bioKompaktDimensions.svg",
        certificatePdf: "../assets/Bio Kompakt 10-20_Bandymu sertifikatas Nr. 02.24-ST [LT].pdf",
        instructionsPdf: "../assets/bio_kompakt_instruction_ee.pdf",
        techSpecPdf: "../assets/bio_kompakt_ee.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/bioKompaktPajungimasEE.pdf",
    }),
    new Katilas({
        id: 3,
        name: "Ekon Max",
        type: "Tahkekütuse",
        imageUrl: "assets/ekon_max.png",
        pjuvis: "assets/ekon_max_pjuvis.png",
        feature: "Tõhus",
        price: 1950,
        pricing:[
            [16, 1950, 0, [0]],
            [20, 2150, 0, [0]],
            [25, 2350, 0, [0]],
            [30, 2550, 0, [0]],
            [40, 2850, 0, [0]],
        ],
        minPower: 16,
        maxPower: 40,
        power: [16, 20, 25, 30, 40],
        minArea: 160,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Saepuru", "Kivisüsi"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 160, 200, 250, 300, 400],
            ["Kütuse laadimine", "l/dm\u00b3", 105, 125, 140, 160, 192],
            ["Põlemiskambri sügavus", "mm", 500, 500, 500, 500, 550],
            ["Kütuse laadimisava suurus", "cm", "29x23", "34x23", "39x23", "44x23", "44x23"],
            ["Soojusvaheti pindala", "m\u00b2", 3.0, 3.4, 3.8, 4.2, 4.8],
            ["Horisontaalsete soojusvahetite kogus", "tk", 3, 3,3,3,3],
            ["Vee kogus boileris", "l", 66,72,78,85,115],
            ["Mass", "kg", 230,260,290,320,350],
            ["Korstna läbimõõt (sisemus)", "mm", "150", "185", "185", "185", "185"],
        ],
        additionalData: [
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "85%"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u00b2 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", ">20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1200, 1200, 1200, 1200, 1300],
            ["B", "mm", 420, 470, 520, 570, 570],
            ["C", "mm", 810, 810, 810, 810, 860],
        ],
        icons: ["em1ee", "em2ee", "em3ee", "em4ee", "em5ee", "em6ee", "em7ee", "em8ee"],
        iconLocations: [[224, 380], [218, 328], [254, 320], [302, 154], [360, 183], [308, 254], [408, 435], [430, 240]],
        description: "Pikka aega kasutatav konstruktsioon kahekordse põlemiskambri ja kolme horisontaalse soojusvahetiga tagab katla parima jõudluse, lihtsa hoolduse ja pika kasutusaja. Katel sobib väga hästi küttepuude ja kivisöe põletamiseks. Soojusvaheti suurim pindala ja kütusekambri maht tagavad kõrge kasuteguri ja väga pika põlemisaja. See on suurepärase hinna ja kvaliteedi suhtega katel.",
        dimensionsImage: "../assets/ekonMaxDimensions.svg",
        techSpecPdf: "../assets/ekon_max_ee.pdf",
        connectionSchemePdf: "../assets/ekonMaxPajungimasEE.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON MAX.pdf",
        instructionsPdf: "../assets/ekon_max_instruction_ee.pdf",
    }),
    new Katilas({
        id: 4,
        name: "Ekon",
        type: "Tahkekütuse",
        imageUrl: "assets/ekon.png",
        pjuvis: "assets/ekon_pjuvis.png",
        feature: "Ökonoomne",
        price: 1300,
        pricing:[
            [10, 1300, 0, [0]],
            [13, 1500, 0, [0]],
            [16, 1650, 0, [0]],
            [20, 1850, 0, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 13, 16, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Saepuru", "Kivisüsi"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 100, 130, 160, 200],
            ["Kütuse laadimine", "l/dm\u00b3", 65, 85, 100, 115],
            ["Põlemiskambri sügavus", "mm", 400, 400, 400, 400],
            ["Kütuse laadimisava suurus", "cm", "29x23", "29x23", "34x23", "39x23"],
            ["Soojusvaheti pindala", "m\u00b2", 1.9, 2.2, 2.6, 2.8],
            ["Horisontaalsete soojusvahetite kogus", "tk", 2,2,2,2],
            ["Vee kogus boileris", "l", 41, 52, 56, 68],
            ["Mass", "kg", 160, 190, 210, 250],
            ["Korstna läbimõõt (sisemus)", "mm", "150", "150", "150", "185"],
        ],
        additionalData: [
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "83%"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u2074 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 910, 1100, 1100, 1150],
            ["B", "mm", 420, 420, 470, 520],
        ],
        icons: ["e1ee", "e2ee", "e3ee", "e4ee", "e5ee", "e6ee", "e7ee", "e8ee"],
        iconLocations: [[224, 360], [230, 316], [258, 318], [304, 126], [360, 150], [320, 210], [405, 435], [420, 220]],
        description: "Pikka aega kasutatav konstruktsioon kahekordse põlemiskambri ja horisontaalsete soojusvahetitega tagab katla parima jõudluse, lihtsa hoolduse ja pika kasutusaja. Katel sobib väga hästi küttepuude, briketi ja kivisöe põletamiseks. Soojusvaheti suur pindala ja kütusekambri maht tagavad kõrge kasuteguri, säästliku põlemise ja väga pika põlemisaja. See on suurepärase hinna ja kvaliteedi suhtega katel.",
        dimensionsImage: "../assets/ekonDimensions.svg",
        techSpecPdf: "../assets/ekon_ee.pdf",
        connectionSchemePdf: "../assets/ekonPajungimasEE.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON.pdf",
        instructionsPdf: "../assets/ekon_instruction_ee.pdf",
    }),
    new Katilas({
        id: 5,
        name: "Klasika",
        type: "Tahkekütuse",
        imageUrl: "assets/klasika.png",
        pjuvis: "assets/klasika_pjuvis.png",
        feature: "Lihtne kasutada",
        price: 1050,
        pricing:[
            [8, 1050, 0, [0]],
            [10, 1150, 0, [0]],
            [13, 1300, 0, [0]],
            [16, 1500, 0, [0]],
            [20, 1650, 0, [0]],
            [25, 1800, 0, [0]],
            [30, 1950, 0, [0]],
        ],
        minPower: 8,
        maxPower: 30,
        power: [8, 10, 13, 16, 20, 25, 30],
        minArea: 80,
        maxArea: 300,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Kivisüsi"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 80, 100, 130, 160, 200, 250, 300],
            ["Kütuse laadimine", "l/dm\u00b3", 60, 72, 90, 105, 125, 140, 160],
            ["Põlemiskambri sügavus", "mm", 400, 400, 500, 500, 500, 500, 500],
            ["Kütuse laadimisava suurus", "cm", "24x23", "29x23", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Soojusvaheti pindala", "m\u00b2", 1.3, 1.5, 1.8, 2.3, 2.5, 2.8, 3.1],
            ["Horisontaalsete soojusvahetite kogus", "tk", 2,2,2,3,3,3,3],
            ["Vee kogus boileris", "l", 38,42,50,59,63,58,72],
            ["Mass", "kg", 135,150,170,200,225,250,275],
            ["Korstna läbimõõt (sisemus)", "mm", "150", "150", "150", "150", "185", "185", "185"],
            ["Hüdrauliliste ühenduste mõõtmed", "tolli", "G 1 \u00b9\u{141F}\u2074", "G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2"]
        ],
        additionalData: [
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "83%"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000, 1000, 1000, 1200, 1200, 1200, 1200],
            ["B", "mm", 370, 420, 420, 420, 470, 520, 570],
            ["C", "mm", 570, 570, 670, 670, 670, 670, 670],
        ],
        icons: ["kl1ee", "kl2ee", "kl3ee", "kl4ee", "kl5ee"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Ammusest ajast kasutataval katlal on klassikaline konstruktsioon, suur küttekolle ja suur soojusvaheti. See tagab väga pika põlemisaja. Katel sobib väga hästi küttepuude ja niiske tahke kütuse põletamiseks. See on odav, töökindel, lihtne ja aja proovile vastu pannud katel.",
        dimensionsImage: "../assets/klasikaDimensions.svg",
        techSpecPdf: "../assets/klasika_ee.pdf",
        connectionSchemePdf: "../assets/klasikaPajungimasEE.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KLASIKA.pdf",
        instructionsPdf: "../assets/klasika_instruction_ee.pdf",
    }),
    new Katilas({
        id: 6,
        name: "Kompakt",
        type: "Tahkekütuse",
        imageUrl: "assets/kompakt.png",
        pjuvis: "assets/kompakt_pjuvis.png",
        feature: "Odav",
        price: 1100,
        pricing:[
            [12, 1100, 0, [0]],
            [16, 1250, 0, [0]],
            [20, 1400, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Kivisüsi"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 120, 160, 200],
            ["Kütuse laadimine", "l/dm\u00b3", 72, 90, 105],
            ["Põlemiskambri sügavus", "mm", 400, 500, 500],
            ["Kütuse laadimisava suurus", "cm", "29x23", "29x23", "29x23"],
            ["Horisontaalsete soojusvahetite kogus", "tk", 2,2,3],
            ["Vee kogus boileris", "l", 42, 50, 59],
            ["Mass", "kg", 150, 170, 200],
            ["Hüdrauliliste ühenduste mõõtmed", "coliai", "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u00b2"],
        ],
        additionalData: [
            ["Korstna läbimõõt (sisemus)", "150 mm"],
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "83%"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", ">15 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000,1000,1200],
            ["B", "mm", 570,670,670],
        ],
        icons: ["ko1ee", "ko2ee", "ko3ee", "ko4ee", "ko5ee"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Pikaealise ja töökindla konstruktsiooniga tahke kütuse katel, suure soojusvaheti ja suure kütusemahutiga. Seda katelt iseloomustab lihtne hooldus ja kasutamine, pikk põlemisaeg. Katla puhastamist hõlbustab eraldi uks. Kompaktne konstruktsioon lubab paigaldada katla ka väikesesse ruumi. Suitsu väljatõmbeklapp on ette nähtud katla süütamise hõlbustamiseks ja samuti vähendab see kütuse laadimisel suitsu ruumi sattumise võimalust. Katel on varustatud kahekordse tõmbe reguleerimise klapiga, tugevate, kergesti avatavate ustega, vastupidavate malmrestidega, mis tagavad kütuse parema põlemise.",
        dimensionsImage: "../assets/kompaktDimensions.svg",
        techSpecPdf: "../assets/kompakt_ee.pdf",
        connectionSchemePdf: "../assets/kompaktPajungimasEE.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KOMPAKT.pdf",
        instructionsPdf: "../assets/kompakt_instruction_ee.pdf",
    }),
    new Katilas({
        id: 7,
        name: "Ignis",
        type: "Tahkekütuse",
        imageUrl: "assets/ignis.png",
        pjuvis: "assets/ignis_pjuvis.png",
        feature: "Praktiline",
        price: 1050,
        pricing:[
            [10, 1050, 0, [0]],
            [15, 1350, 0, [0]],
        ],
        minPower: 10,
        maxPower: 15,
        power: ["M 10", "D 15"],
        minArea: 100,
        maxArea: 150,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Kivisüsi"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 100, 150],
            ["Kütuse laadimine", "l/dm\u00b3", 70, 90],
            ["Põlemiskambri sügavus", "mm", 300, 400],
            ["Kütuse laadimisava suurus", "cm", "34x26", "34x26"],
            ["Soojusvaheti pindala", "m\u00b2", 1.2, 1.8],
            ["Vertikaalsete soojusvahetite hulk", "tk", 1, 2],
            ["Vee kogus boileris", "l", 32, 42],
            ["Mass", "kg", 130, 190],
            ["Kütmise efektiivsus", "%", 76, 78],
            ["Korstna läbimõõt (sisemus)", "mm", "130", "150"]
        ],
        additionalData: [
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u2074 tolli"],
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", "15-20 Pa"], 
        ],
        selections: [
            [""],
            [""],
        ],
        icons: ["i1ee", "i2ee", "i3ee", "i4ee", "i5ee"],
        iconLocations: [[233, 190], [350, 220], [434, 260], [290, 240], [430, 204]],
        description: "Katel-pliit, traditsiooniline. aja testitud toode. Katlal on väga suur küttepuude kamber ja suur soojusvaheti. Konstruktsioon tagab katla kõrge tõhususe ja pika põlemisaja. Kasutatud on kvaliteetne, sertifitseeritud 5-millimeetri paksune terasleht tagab pika kasutusea. Tänu laitmatule kvaliteedile ja töökindlale konstruktsioonile antakse tootele 4-aastane garantii. Katelt saab kasutada nii suve- kui talverežiimil, vajadusel saab paigaldada automaatse tõmberegulaatori. Katel on varustatud kvaliteetse malmist pliidiplaadiga, suunda vahetatava uksega ja mugava mehaanilise tõmberegulaatoriga.",
        dimensionsImage: "../assets/ignisDimensions.svg",
        techSpecPdf: "../assets/ignis_ee.pdf",
        connectionSchemePdf: "../assets/ignisPajungimasEE.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos IGNIS.pdf",
        instructionsPdf: "../assets/ignis_instruction_ee.pdf",
    }),
    new Katilas({
        id: 8,
        name: "Agro Uni",
        type: "Põllumajandusjäätmete",
        imageUrl: "assets/agro_uni.png",
        pjuvis: "assets/agro_uni_pjuvis.png",
        feature: "Ökoloogiline",
        price: 2750,
        pricing:[
            [15, 2750, 0, [0,250]],
            [20, 3100, 0, [0,250]],
            [30, 3500, 0, [0,350]],
            [40, 4000, 0, [0,350]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15, 20, 30, 40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Teravili", "Põllumajandussaadused", "Graanulid", "Hakkepuit", "Kivisüsi 0-50", "Küttepuud"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 150, 200, 300, 400],
            ["Põlemiskambri sügavus", "mm", 450, 450, 500, 600],
            ["Kütuse laadimine", "l/dm\u00b3", 35, 40, 65, 104],
            ["„Zenono“ põleti", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Soojusvaheti pindala", "m\u00b2", 1.9, 2.5, 3.3, 4.2],
            ["Kütuse laadimisava suurus", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Horisontaalsete soojusvahetite kogus", "tk", 3, 4, 4, 4],
            ["Vee kogus boileris", "l", 48, 62, 69, 100],
            ["Mass", "kg", 180, 230, 270, 310],
            ["Korstna läbimõõt (sisemus)", "mm", "150", "150", "150", "185"],
        ],
        additionalData: [
            ["Kütuse maht su maišykle", "400/600 l/dm\u00b3"],
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "90%"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u2074 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Võimsus", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["au1ee", "au2ee", "au3ee", "au4ee", "au5ee", "au6ee", "au7ee"],
        iconLocations: [[240, 350], [240, 350], [410, 316], [235, 320], [240, 190], [240, 190], [320, 210]],
        description: "Eriti universaalne katel põllumajandusjäätmete automaatseks põletamiseks, milles võib põletada erinevaid teraviljakultuure, põllumajandusjäätmeid, halva kvaliteediga pellteid, laaste, saepuru ja kivisütt, küttepuid ja muud tahket kütust, mis laaditakse läbi keskmise ukse. Kütusemahuti spetsiaalne segamismehhanism, isepuhastuv põleti, suur tuhakast ja muud lahendused tagavad erinevat tüüpi kütuse sujuva põletamise ja katla töö. Suur horisontaalne neljakäiguline soojusvaheti tagab mugava puhastamise ja katla kõrge kasuteguri. Katel on varustatud Zenoni põletiga. Me ei soovita katelt varustada roostevabast terasest suitsulõõriga.",
        burnerDescription: "Spetsialiseeritud põleti, mis suudab teraviljakultuure, põllumajandusjäätmeid, ebakvaliteetseid graanuleid ja erinevat kivisütt põletada automaatrežiimil. Põletil on roostevabast terasest puhastusmehhanism, mis eemaldab suurepäraselt põlemisel tekkinud räbu.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroUniDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO UNI.pdf",
        instructionsPdf: "../assets/agro_uni_instruction_ee.pdf",
        techSpecPdf: "../assets/agro_uni_ee.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroUniPajungimasEE.pdf",
    }),
    new Katilas({
        id: 9,
        name: "Universa",
        type: ["Graanul", "Põllumajandusjäätmete"],
        imageUrl: "assets/universa.png",
        pjuvis: "assets/universa_pjuvis.png",
        feature: "Populaarne",
        price: 2550,
        pricing:[
            [15, 2550, 0, [0, 50]],
            [20, 2900, 0, [0, 50]],
            [30, 3300, 0, [0, 50]],
            [40, 3850, 0, [0, 50]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15,20,30,40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Graanulid", "Teravili", "Turvas", "Põhugraanulid", "Päevalilleseemnegraanulid", "Kivisüsi 0-50"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 150,200,300,400],
            ["Põlemiskambri sügavus", "mm", 450,450,500,600],
            ["Kütuse laadimine", "l/dm\u00b3", 35,40,65,104],
            ["„Zenono“ põleti", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Soojusvaheti pindala", "m\u00b2", 1.9,2.5,3.3,4.2],
            ["Kütuse laadimisava suurus", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Horisontaalsete soojusvahetite kogus", "tk", 3, 4, 4,4],
            ["Vee kogus boileris", "l", 48,62,69,100],
            ["Mass", "kg", 180,230,270,320],
            ["Korstna läbimõõt (sisemus)", "mm", "150","150","150","185"],
        ],
        additionalData: [
            ["Kütuse maht", "230/300 l/dm\u00b3"],
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "90%"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 1 \u00b9\u{141F}\u2074 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "200 l", "300 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Võimsus", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["u1ee", "u2ee", "u3ee", "u4ee", "u5ee", "u6ee"],
        iconLocations: [[240, 350], [240, 350], [234, 320], [246, 192], [246, 192], [330, 200]],
        description: "Eriti universaalne automaatrežiimil töötav pelletikatel, mis on ette nähtud põletama nii hea kui ka halva kvaliteediga pelleteid, aga ka kõiki teraviljakultuure, põllumajanduslikke puistejäätmeid, turvast ja kivisütt, samuti küttepuid või muud tahket kütust, mida laaditakse läbi keskmise ukse. Soodne, töökindel ja universaalne pelletkütuse katel, mille konstruktsioon on kohandatud spetsiaalselt erinevate kütuseliikide põletamiseks. Suur horisontaalne nelja pöördega soojusvaheti tagab mugava puhastamise ja kõrge kasuteguri. Katel on varustatud Zenoni põletiga.",
        burnerDescription: "Spetsialiseeritud põleti, mis suudab teraviljakultuure, põllumajandusjäätmeid, ebakvaliteetseid graanuleid ja erinevat kivisütt põletada automaatrežiimil. Põletil on roostevabast terasest puhastusmehhanism, mis eemaldab suurepäraselt põlemisel tekkinud räbu.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/universaDimensions.svg",
        talpa: ['UT 200', 'UT 300'],
        talpaData:[
            ["A1", "mm", 1200, 1300],
            ["B1", "mm", 600, 750]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos UNIVERSA.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        techSpecPdf: "../assets/universa_ee.pdf",
        connectionSchemePdf: "../assets/universaPajungimasEE.pdf",
        instructionsPdf: "../assets/universa_instruction_ee.pdf",
    }),
    new Katilas({
        id: 10,
        name: "Pele Max",
        type: "Tööstuslik",
        imageUrl: "assets/pelle_max.png",
        pjuvis: "assets/pelle_max_pjuvis.png",
        feature: "Ökonoomne",
        price: 6140,
        pricing:[
            [50, 6140, 460, [0, 100, 300]],
            [70, 7600, 900, [0, 100, 300]],
            [100, 10000, 700, [0, 100, 300]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50,70,100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Kivisüsi", "Graanulid"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 500,700,1000],
            ["Põlemiskambri sügavus", "mm", 680,680,980],
            ["Kütuse laadimine", "l/dm\u00b3", 105,145,280],
            ["Pöörlev pelletipõleti", "kW", "10-50", "15-70", "20-100"],
            ["Soojusvaheti pindala", "m\u00b2", 6, 7.8, 11.8],
            ["Kütuse laadimisava suurus", "cm", "50x26", "60x26", "70x26"],
            ["Horisontaalsete soojusvahetite kogus", "tk", 5,5,5],
            ["Vee kogus boileris", "l", 142,180,280],
            ["Mass", "kg", 500,650,890],
            ["Korstna läbimõõt (sisemus)", "mm", "185", "185", "200"],
        ],
        additionalData: [
            ["Kütuse maht", "500/700/1000 l/dm\u00b3"],
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "90%"],
            ["Hüdrauliliste ühenduste mõõtmed", "G 2 tolli"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", ">20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "500 l", "700 l", "1000 l"],
            [
                ["Degiklis"],
                ["50", "8-36 kW", "10-50 kW"],
                ["70", "10-50 kW", "15-70 kW"],
                ["100", "15-70 kW", "20-100 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1585, 1585, 1735],
            ["A1", "mm", 1275, 1355, 1430],
            ["B", "mm", 630, 730, 830],
            ["B1", "mm", 650, 750, 850],
            ["C", "mm", 890, 890, 1195],
            ["C1", "mm", 265, 340, 340],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Võimsus", "36-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pm1ee", "pm2ee", "pm3ee", "pm4ee", "pm5ee"],
        iconLocations: [[242, 162], [252, 320], [310, 380], [235, 286], [370, 190]],
        description: "Tööstuslikuks kasutamiseks mõeldud universaalne pelletikatel, kaasaegse ja kvaliteetse pöördpõletiga, millega saab põletada ka halvema kvaliteediga pelleteid. Tegemist on universaalse katlaga, millega saab põletada nii täisautomaatrežiimil pelleteid kui ka erinevat käsitsi laaditavat tahkekütus. Katlal on üks suurimaid soojusvaheti pindlasid turul. Viis horisontaalset soojusvahetit, palju pöördeid ja suitsu pikk tee tagavad katla väga kõrge kasuteguri.",
        burnerDescription: "Täisautomaatne graanulite põleti, hea kuni keskmise kvaliteediga graanulite põletamiseks. Põletil on automaatne puhastusmehhanism - pöörlev põlemis- ja õhuvarustuskamber, mille abil põleti puhastab end tuhast ja räbust.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/pele_max_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/peleMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos PELE MAX.pdf",
        instructionsPdf: "../assets/pele_max_instruction_ee.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        techSpecPdf: "../assets/pele_max_ee.pdf",
        connectionSchemePdf: "../assets/peleMaxPajungimasEE.pdf",
    }),
    new Katilas({
        id: 11,
        name: "Agro Max",
        type: ["Põllumajandusjäätmete", "Tööstuslik"],
        imageUrl: "assets/agro_max.png",
        pjuvis: "assets/agro_max_pjuvis.png",
        feature: "Universaalne",
        price: 5700,
        pricing:[
            [50, 5700, 0, [0,200,400]],
            [70, 6900, 0, [0,200,400]],
            [100, 8500, 300, [0,200,400]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A",
        fuelTypes: ["Teravili", "Põllumajandussaadused", "Graanulid", "Hakkepuit", "Kivisüsi 0-50", "Küttepuud"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 500, 700, 1000],
            ["Põlemiskambri sügavus", "mm", 680, 680, 980],
            ["Kütuse laadimine", "l/dm\u00b3", 140, 170, 290],
            ["„Zenono“ põleti", "kw", "15-70", "15-70", "20-100"],
            ["Soojusvaheti pindala", "m\u00b2", 5.9, 7.1, 11],
            ["Kütuse laadimisava suurus", "cm", "50x34", "60x34", "70x34"],
            ["Horisontaalsete soojusvahetite kogus", "tk", 5, 5, 5],
            ["Vee kogus boileris", "l", 145, 165, 280],
            ["Mass", "kg", 550, 650, 930],
            ["Korstna läbimõõt (sisemus)", "mm", "185", "185", "200"],
            ["Kütuse maht", "l/dm\u00b3", 400, 600, 800]
        ],
        additionalData: [
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "90%"],
            ["Hüdrauliliste ühenduste mõõtmed", "G2 coliai"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", ">20 Pa"],
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l", "800 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1590, 1590, 1740],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 890, 890, 1190],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Võimsus", "50-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["am1ee", "am2ee", "am3ee", "am4ee", "am5ee", "am6ee"],
        iconLocations: [[270, 350], [270, 350], [450, 306], [274, 306], [280, 162], [380, 274]],
        description: "Tegemist on eriti universaalse tööstuslikuks kasutamiseks mõeldud katlaga, mis on ette nähtud erinevate teraviljakultuuride, põllumajandusjäätmete, ebakvaliteetsete graanulite ja erineva kivisöe põletamiseks automaatrežiimil. See on kõrgeima kategooria ja tehnilise tasemega toode, mis on spetsiaalselt konstrueeritud sellise kütuse põletamiseks. Suur mahutavus koos segamismehhanismiga, isepuhastuv põleti, suur tuhakast ja muud eelised tagavad katla lihtsa kasutamise. Suur horisontaalne viiekäiguline soojusvaheti tagab mugava puhastamise ja kõrge kasuteguri. Katlas saab põletada ka küttepuid ja muud tahket kütust, mis laaditakse läbi keskmise ukse. Katel on varustatud Zenono põletiga.",
        burnerDescription: "Spetsialiseeritud põleti, mis suudab teraviljakultuure, põllumajandusjäätmeid, ebakvaliteetseid graanuleid ja erinevat kivisütt põletada automaatrežiimil. Põletil on roostevabast terasest puhastusmehhanism, mis eemaldab suurepäraselt põlemisel tekkinud räbu.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroMaxDimensions.svg",
        talpa: ['AT 400', 'AT 600', 'AT 800'],
        talpaData:[
            ["A1", "mm", 1500, 1700, 1700],
            ["B1", "mm", 1080, 1080, 1190]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO MAX.pdf",
        instructionsPdf: "../assets/agro_max_instruction_ee.pdf",
        techSpecPdf: "../assets/agro_max_ee.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroMaxPajungimasEE.pdf",
    }),
    new Katilas({
        id: 12,
        name: "Bio Max",
        type: "Tööstuslik",
        imageUrl: "assets/bio_max.png",
        pjuvis: "assets/bio_max_pjuvis.png",
        feature: "Praktiline",
        price: 3500,
        pricing:[
            [50, 3500, 0, [0]],
            [70, 4500, 0, [0]],
            [100, 5800, 0, [0]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Küttepuud", "Brikett", "Kivisüsi"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Köetav pind", "kuni m\u00b2", 500, 700, 1000],
            ["Kütuse laadimine", "l/dm\u00b3", 240, 380, 550],
            ["Põlemiskambri sügavus", "mm", 600, 800, 1000], 
            ["Kütuse laadimisava suurus", "cm", "40x50", "40x60", "40x70"],
            ["Soojusvaheti pindala", "m\u00b2", 5, 7.1, 10],
            ["Horisontaalsete soojusvahetite kogus", "tk", 4, 4, 4],
            ["Vee kogus boileris", "l", 130, 180, 235],
            ["Mass", "kg", 420, 570, 800],
            ["Korstna läbimõõt (sisemus)", "mm", "200", "220", "250"],
        ],
        additionalData: [
            ["Hüdrauliliste ühenduste mõõtmed", "G 2 coliai"],
            ["Minimaalne katla töötemperatuur", "60 \u2070C"],
            ["Maksimaalne katla töötemperatuur", "90 \u2070C"],
            ["Kütmise efektiivsus", "83%"],
            ["Maksimaalne töörõhk", "1.5 bar"],
            ["Nõutav tõmme korstnas", ">20 Pa"],
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1600, 1600, 1600],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 800, 1000, 1200],
        ],
        icons: ["bm1ee", "bm2ee", "bm3ee", "bm4ee", "bm5ee"],
        iconLocations: [[312, 152], [300, 280], [414, 440], [370, 150], [440, 280]],
        description: "See on klassikalise konstruktsiooniga, töökindel ja lihtsalt kasutatav tööstuslik katel. Sellel katlal on ka väga suur kütusekamber, suur soojusvaheti, pikk kasutusiga ja madal hind. Katla kütusekambri maht ja soojusvaheti pindala on turul ühed suurimad. See tagab väga pika põlemisaja, katel sobib väga hästi küttepuude ja niiskema kütuse põletamiseks.",
        dimensionsImage: "../assets/bioMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO MAX.pdf",
        techSpecPdf: "../assets/bio_max_ee.pdf",
        connectionSchemePdf: "../assets/bioMaxPajungimasEE.pdf",
        instructionsPdf: "../assets/bio_max_instruction_ee.pdf",
    }),
];